<template>
  <div>
    <vx-card>
      <div class="vx-row find-people">
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <div class="card-title more-card-title">
            <h2 class="mb-2">Lead Handling Workflow</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              quam velit, vulputate eu pharetra nec, mattis ac neque. Duis
              vulputate commodo lectus, ac blandit elit tincidunt id.
            </p>
          </div>
          <div class="common-table p-4">
            <div class="flex btn-search w-full">
              <h5 class="mb-2">CRM Export Filters</h5>
            </div>
            <!-- table -->
            <vs-table v-model="selected" :data="users" pagination>
              <template slot="thead">
                <vs-th>Enabled</vs-th>
                <vs-th>Rule Name</vs-th>
                <vs-th>Scope</vs-th>
                <vs-th> Assigned to </vs-th>
                <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td> <vs-checkbox> </vs-checkbox></vs-td>
                  <vs-td :data="data[indextr].filterName">
                    {{ data[indextr].filterName }}
                  </vs-td>
                  <vs-td :data="data[indextr].scope">
                    {{ data[indextr].scope }}
                  </vs-td>
                  <vs-td :data="data[indextr].createdBy">
                    {{ data[indextr].createdBy }}
                  </vs-td>
                  <vs-td>
                    <div class="table-action w-full">
                      <feather-icon
                        class="mr-2 float-right"
                        icon="ArrowDownIcon"
                      ></feather-icon>
                      <feather-icon
                        class="mr-2 float-right"
                        icon="ArrowUpIcon"
                      ></feather-icon>
                      <vs-button
                        title="Copy"
                        color="warning"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-copy"
                        class="mr-2 float-right"
                        @click="customrulesPopup = true"
                      ></vs-button>
                      <vs-button
                        title="Delete"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-minus"
                        class="mr-2 float-right"
                        @click="deletePopup = true"
                      ></vs-button>
                      <vs-button
                        title="Plus"
                        color="success"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        class="mr-2 float-right"
                        @click="customrulesPopup = true"
                      ></vs-button>
                      <vs-button
                        title="Edit"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2 float-right"
                        @click="customrulesPopup = true"
                      ></vs-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div class="float-left my-3 w-full">
              <vs-button
                title="Save"
                color="primary"
                type="filled"
                class="float-left px-6"
                >{{ LabelConstant.buttonLabelSave }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- Lead Handling Workflow - Detail-->

    <vs-popup
      class="campaign-second popup-width"
      title="Lead Handling Workflow - Detail"
      :active.sync="customrulesPopup"
    >
      <template>
        <div class="vx-row">
          <!-- Keywords -->
          <div class="vx-col w-full mb-2">
            <p class="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              quam velit, vulputate eu pharetra nec, mattis ac neque.
            </p>
          </div>
        </div>
        <form data-vv-scope="senderForm" class="from-to-camp">
          <!-- step-1 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="1" />
                </div>
                <div>
                  <p>Set the Rule Name</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>
                  Give your rule a meaningful name that allows you to quickly
                  know the purpose of the workflow.
                </p>
                <vs-divider class="mt-0" />
              </div>

              <div class="vx-col flex items-center mb-6 w-full">
                <h6 class="pr-4 float-left w-auto">Rule Name:</h6>
                <vs-input
                  class="w-2/3"
                  placeholder="Assign to Jane"
                  name="emailSubject"
                />
              </div>
            </div>
          </div>
          <!-- step-2 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="2" />
                </div>
                <div>
                  <p>Setup Triggers</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Filter by VisualVisitor Application and settings.</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col w-full">
                <h5>Triggers</h5>
              </div>
              <div
                class="
                  vx-col
                  flex flex-wrap
                  items-center
                  mb-1
                  w-full
                  export-crm-group
                "
              >
                <p class="pr-4 my-2 float-left w-auto">If</p>
                <div class="md:w-1/3 pr-4 w-full my-2">
                  <v-select
                    placeholder="All"
                    label="title"
                    :options="options"
                  />
                </div>
                <p class="pr-4 float-left w-auto my-2">
                  of the following conditions are met
                </p>
                <vs-button
                  title="Add Group"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="ml-auto my-2"
                >
                  Add Group</vs-button
                >
              </div>
              <div class="vx-col w-full mb-6">
                <div class="vx-row">s
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign my-0">
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="VV.Service"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="IN"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input
                              class="w-auto"
                              name="emailSubject"
                              placeholder="Who's Shopping, Who's Looking, Call Tracking"
                            />
                          </div>
                          <div
                            class="
                              table-action
                              justify-center
                              md:w-1/5
                              w-full
                              flex
                              my-2
                            "
                          >
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              @click="singleinputPopup = true"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                            ></vs-button>
                          </div>
                        </div>
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="VV.RecordType"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="IS"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input
                              class="w-auto"
                              name="emailSubject"
                              placeholder="Prospect"
                            />
                          </div>
                          <div
                            class="
                              table-action
                              justify-center
                              md:w-1/5
                              w-full
                              flex
                              my-2
                            "
                          >
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                            ></vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step-3 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="3" />
                </div>
                <div>
                  <p>Setup Record Filter</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Filters the trigger data using record field values.</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col w-full">
                <h5>Record Filters</h5>
              </div>
              <div
                class="
                  vx-col
                  flex flex-wrap
                  items-center
                  mb-1
                  w-full
                  export-crm-group
                "
              >
                <p class="pr-4 my-2 float-left w-auto">If</p>
                <div class="md:w-1/3 pr-4 w-full my-2">
                  <v-select
                    placeholder="All"
                    label="title"
                    :options="options"
                  />
                </div>
                <p class="pr-4 float-left w-auto my-2">
                  of the following conditions are met
                </p>
                <vs-button
                  title="Add Group"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="ml-auto my-2"
                >
                  Add Group</vs-button
                >
              </div>
              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign my-0">
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="Company.State"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="IN"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input
                              class="w-auto"
                              name="emailSubject"
                              placeholder="Florida, Georgia"
                            />
                          </div>
                          <div
                            class="
                              table-action
                              justify-center
                              md:w-1/5
                              w-full
                              flex
                              my-2
                            "
                          >
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              @click="singleinputPopup = true"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                            ></vs-button>
                          </div>
                        </div>
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="Contact.State"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="IN"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input
                              class="w-auto"
                              name="emailSubject"
                              placeholder="Florida, Georgia"
                            />
                          </div>
                          <div
                            class="
                              table-action
                              justify-center
                              md:w-1/5
                              w-full
                              flex
                              my-2
                            "
                          >
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                            ></vs-button>
                          </div>
                        </div>
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="Lead.State"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="IN"
                              label="title"
                              :options="options"
                            />
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input
                              class="w-auto"
                              name="emailSubject"
                              placeholder="Florida, Georgia"
                            />
                          </div>
                          <div
                            class="
                              table-action
                              justify-center
                              md:w-1/5
                              w-full
                              flex
                              my-2
                            "
                          >
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                            ></vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step-4 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="4" />
                </div>
                <div>
                  <p>Setup Action</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>If conditions are met the execute the following actions</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="row w-full mx-2">
                <div class="vx-col w-full find-people">
                  <div class="filter-campaign mb-6 mt-0">
                    <div class="flex flex-wrap items-center mb-1 w-full">
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          px-3
                        "
                      >
                        <div class="w-full  flex
                          w-full flex-wrap my-1">
                           <div class="lg:w-1/6  mb-2  w-full ">
                          <h5>Update Object: </h5>
                           </div>
                          <vs-radio class="mr-2 mb-2" v-model="picked" val="1">
                           Company
                          </vs-radio>
                          <vs-radio v-model="picked1" val="2" class="mb-2">
                           Company and Employee
                          </vs-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-col mb-1 w-full">
                <h5>Actions</h5>
              </div>

              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign my-0">
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <v-select
                              placeholder="Assign Record Ownership to"
                              label="title"
                              :options="options"
                            />
                          </div>

                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input
                              class="w-auto"
                              name="emailSubject"
                              placeholder="Jane"
                            />
                          </div>
                          <div
                            class="
                              table-action
                              justify-center
                              md:w-1/5
                              w-full
                              flex
                              my-2
                            "
                          >
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              @click="singleinputPopup = true"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                            ></vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step-5 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="5" />
                </div>
                <div>
                  <p>Setup Scope</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Define who can see and modify the rule</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col mb-1 w-full">
                <h5>Scope</h5>
              </div>

              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign my-0">
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div
                            class="
                              flex
                              w-full
                              flex-d-column
                              justify-start
                              mt-2
                              px-3
                            "
                          >
                            <div class="w-full my-1">
                              <vs-radio v-model="picked" val="1">
                                Admin Only
                              </vs-radio>
                            </div>
                            <div class="w-full my-1">
                             
                                <div class="flex flex-wrap items-center w-full">
                                   <vs-radio v-model="picked" val="2" class="mb-2">
                                  Assign to User
                                  </vs-radio>
                                  <div class="md:w-1/3 ml-2 w-full mb-2">
                                    <v-select
                                      placeholder="Jane"
                                      label="title"
                                      :options="options"
                                      class="width-150"
                                    />
                                  </div>
                                </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Update" color="primary" type="filled" class="mr-2">{{
          LabelConstant.buttonLabelSave
        }}</vs-button>
        <vs-button
          color="grey"
          @click="customrulesPopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
      <!--singleinputPopup  -->
      <vs-popup class="" title="single input" :active.sync="singleinputPopup">
        <template>
          <form data-vv-scope="senderForm">
            <div class="vx-row">
              <div class="vx-col mb-6 w-full">
                <p class="w-full float-left">Lead.type IS</p>
                <div class="vx-row align-center">
                  <div class="vx-col mb-6 w-full">
                    <vs-input
                      class="w-full"
                      placeholder="placeholder"
                      name="emailSubject"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </template>
        <div class="text-right mt-6 mb-8">
          <vs-button
            title="Update"
            color="primary"
            type="filled"
            class="mr-2"
            >{{ LabelConstant.buttonLabelSave }}</vs-button
          >
          <vs-button
            color="grey"
            @click="singleinputPopup = false"
            type="border"
            class="ml-4 grey-btn mr-3"
            >{{ LabelConstant.buttonLabelCancel }}</vs-button
          >
        </div>
      </vs-popup>
    </vs-popup>
  </div>
</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
  },
  data: () => ({
    customrulesPopup: false,
    singleinputPopup: false,
    validationrulesPopup: false,
    customfieldsPopup: false,
    conversionPopup: false,
    picked: 1,
    picked1: 1,
    label: "Lead.type",
    selected: "Leanne",
    options: ["Leanne", "Bret", "baz", "Lead.type"],
    users: [
      {
        id: 1,
        module: "All",
        service: "All",
        filterName: "Assign to Jane",
        scope: "User",
        createdBy: "Jane",
      },
      {
        id: 2,
        module: "Contact",
        service: "All",
        filterName: "Assign to Jane",
        scope: "User",
        createdBy: "Jane",
      },
      {
        id: 3,
        module: "Contact",
        service: "Call Tracking",
        filterName: "Assign to Jane",
        scope: "User",
        createdBy: "Jane",
      },
      {
        id: 4,
        module: "All",
        service: "Who's Looking",
        filterName: "Assign to Jane",
        scope: "User",
        createdBy: "Jane",
      },
      {
        id: 5,
        module: "Account",
        service: "All",
        filterName: "Assign to Jane",
        scope: "User",
        createdBy: "Jane",
      },
    ],
  }),
};
</script>